import React from 'react';
import { Link, PageProps, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { rhythm } from '../utils/typography';
import { DataProps } from '../types';
import Header from '../components/Header';
import { generateLink } from '../utils/utils';
import './../styles.css';

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/(blog)/.*\\.md$/"}}, 
      sort: { fields: [frontmatter___date], order: DESC },
      limit: $limit, 
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 180)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            slug
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

const BlogList = ({ data, pageContext }: PageProps<DataProps>): JSX.Element => {
  const posts = data.allMarkdownRemark.edges;
  const { numPages, currentPage } = pageContext as any;

  function getPagination() {
    const maxItems = 5;
    const lower = currentPage - maxItems;
    const upper = currentPage + maxItems;

    // Only show page numbers within lower and upper
    const pageNums = Array.from({ length: numPages }).map((_, index) => {
      if (index <= 0 || index >= numPages - 1 || index < lower || index > upper) return null;

      return (
        <a key={index} href={`/${index}`} className={currentPage === index ? 'active' : ''}>
          <button>{index + 1}</button>
        </a>
      );
    });

    return (
      <div className="pagination">
        {/** previous page */}
        {currentPage === 0 || (
          <a href={`/${currentPage - 1}`}>
            <button>&larr;</button>
          </a>
        )}
        {/** first page */}
        <a href={`/`} className={currentPage === 0 ? 'active' : ''}>
          <button>1</button>
        </a>
        {/** omitted pages */}
        {lower > 1 ? '...' : null}
        {/** displayed pages */}
        {pageNums}
        {/** omitted pages */}
        {upper < numPages - 1 ? '...' : null}
        {/** last page */}
        <a href={`/${numPages - 1}`} className={currentPage === numPages - 1 ? 'active' : ''}>
          <button>{numPages}</button>
        </a>
        {/** next page */}
        {currentPage >= numPages - 1 || (
          <a href={`/${currentPage + 1}`}>
            <button>&rarr;</button>
          </a>
        )}
      </div>
    );
  }

  return (
    <>
      <Header pageId={'home'} />
      <Layout>
        <SEO title="All posts" />
        <Bio />
        {posts.map(
          ({ node }, index): JSX.Element => {
            const title = node.frontmatter.title || node.fields.slug;
            const { slug, date } = node.frontmatter;

            return (
              <article
                key={node.fields.slug}
                className={`blog-item ${index % 2 === 0 ? 'rythm-shake-1' : 'rythm-shake-2'}`}
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                }}
              >
                {node.frontmatter?.featuredImage?.childImageSharp?.fluid && (
                  <div style={{ maxWidth: '700px', width: '100%' }}>
                    <Link to={generateLink(date, slug)}>
                      <Img fluid={node.frontmatter?.featuredImage?.childImageSharp?.fluid} />
                    </Link>
                  </div>
                )}
                <div style={{ margin: '10px 40px', width: '100%' }}>
                  <header>
                    <h3
                      className="blog-title"
                      style={{
                        marginBottom: rhythm(1 / 4),
                      }}
                    >
                      <Link style={{ boxShadow: `none` }} to={generateLink(date, slug)}>
                        {title}
                      </Link>
                    </h3>
                    <small>{node.frontmatter.date}</small>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.excerpt,
                      }}
                    />
                  </section>
                </div>
              </article>
            );
          },
        )}
        {getPagination()}
      </Layout>
    </>
  );
};

export default BlogList;
